import React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import Team from '../assets/images/team_background.jpeg';

import config from '../../config';
import CourseCard from '../components/CourseCard';
const IndexPage = () => {
  const [width, setWidth] = useState(200);
  const [test, setTest] = useState('');
  const [showCode, setShowCode] = useState(false);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const [profession, setProfession] = useState('');
  const [state, setState] = useState('');
  const [professionStates, setProfessionStates] = useState([]);

  // Enter a book code hotfix map.
  const bookCodeMap = {
    ENG: 'Engineering',
    COS: 'Cosmetology',
    VET: 'Veterinarians',
  };

  // When a profession is selected, load a list of states for that profession
  const onProfessionSelected = (profession) => {
    setProfession(profession);
    switch (profession) {
      case 'Engineering':
        setProfessionStates([
          { value: 'FL', label: 'Florida' },
          { value: 'MS', label: 'Mississippi' },
          { value: 'TX', label: 'Texas' },
          { value: 'IA', label: 'Iowa' },
          { value: 'NM', label: 'New Mexico' },
          { value: 'WI', label: 'Wisconsin' },
          // { value: 'NJ', label: 'New Jersey' },
        ]);
        break;
      case 'Cosmetology':
        setProfessionStates([
          { value: 'FL', label: 'Florida' },
          { value: 'OH', label: 'Ohio' },
        ]);
        break;
      case 'Veterinarians':
        setProfessionStates([{ value: 'FL', label: 'Florida' }]);
        break;
    }
  };

  const toggleShowCode = () => {
    setShowCode(!showCode);
  };

  const loadStateOptions = () => {
    if (!professionStates) return null;
    return professionStates.map((state) => {
      return (
        <option key={state.value} value={state.value}>
          {state.label}
        </option>
      );
    });
  };

  return (
    <Layout>
      <section
        id="banner"
        style={{
          backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${Team})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="inner">
          <h2>{config.heading}</h2>
          <p>{config.subHeading}</p>
          <ul className="actions special" style={{ marginBottom: 0 }}>
            {!showCode && (
              <li>
                <Scroll type="id" element="one">
                  <a className="button primary">Find Courses Now</a>
                </Scroll>
              </li>
            )}
            {!showCode && (
              <li>
                <a onClick={toggleShowCode} className="button secondary">
                  Enter a Book Code
                </a>
              </li>
            )}
          </ul>
          {showCode && (
            <ul className="actions special">
              <li>
                {/* <h3>Enter Book Code</h3> */}
                {/* <p>Enter your book code to be taken to the test.</p> */}
                <input
                  autoFocus
                  // style={{
                  //   color: 'black',
                  // }}
                  id="book-code"
                  type="text"
                  placeholder="FLENG51"
                  onChange={(e) => setTest(e.target.value)}
                />

                <a
                  style={{ width: '100%', marginTop: 15 }}
                  className="button primary"
                  onClick={() => (window.location.href = `/${test.substr(0, 2)}/${bookCodeMap[test.substr(2, 3)]}/${test}`)}
                >
                  Submit
                </a>
                <a style={{ width: '100%' }} className="button secondary" onClick={toggleShowCode}>
                  Cancel
                </a>
              </li>
            </ul>
          )}
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="more">
            Learn More
          </a>
        </Scroll>
      </section>

      <section id="one" className="wrapper style3 special" style={{ minHeight: 600 }}>
        <div className="inner">
          <header className="major">
            <h2>Select Your Profession</h2>
          </header>
          <select onChange={(e) => onProfessionSelected(e.target.value)}>
            <option value="">Select a Profession</option>
            <option value="Engineering">Engineering</option>
            <option value="Cosmetology">Cosmetology</option>
            <option value="Veterinarians">Veterinarians</option>
            {/* <option value="Continuing Education~Nursing">Nursing</option>
            <option value="Continuing Education~Social Work">Social Work</option>
            <option value="Continuing Education~Physical Therapy">Physical Therapy</option>
            <option value="Continuing Education~Massage Therapists">Massage Therapists</option>
            <option value="Continuing Education~Counselors">Counselors</option>
            <option value="Continuing Education~Dental">Dental</option>
            <option value="Continuing Education~Funeral">Funeral</option>
            <option value="Continuing Education~Occupational Therapy">Occupational Therapy</option>
            <option value="Continuing Education~Pharmacy Tech">Pharmacy Tech</option>
            <option value="Continuing Education~Pharmacy">Pharmacy</option>
            <option value="Continuing Education~Physician">Physician</option>
            <option value="Continuing Education~Psychology">Psychology</option>
            <option value="Continuing Education~Respiratory Therapy">Respiratory Therapy</option> */}
          </select>
          {profession !== '' && (
            <>
              <select
                style={{ marginTop: 30, marginBottom: 30 }}
                onChange={(e) => (window.location.href = `/${e.target.value}/${profession}`)}
              >
                <option value="0">2. Select State</option>
                {loadStateOptions()}

                {/* <option value="Alabama">Alabama</option>
              <option value="Alaska">Alaska</option>
              <option value="Arizona">Arizona</option>
              <option value="Arkansas">Arkansas</option>
              <option value="California">California</option>
              <option value="Colorado">Colorado</option>
              <option value="Connecticut">Connecticut</option>
          <option value="Delaware">Delaware</option> */}
                {/* <option value="FL">Florida</option>
                <option value="IA">Iowa</option>
                <option value="MS">Mississippi</option>
                <option value="NM">New Mexico</option>
                <option value="TX">Texas</option> */}
                {/* <option value="Georgia">Georgia</option>
              <option value="Guam">Guam</option>
              <option value="Hawaii">Hawaii</option>
              <option value="Idaho">Idaho</option>
              <option value="Illinois">Illinois</option>
              <option value="Indiana">Indiana</option>
              <option value="Kansas">Kansas</option>
              <option value="Kentucky">Kentucky</option>
              <option value="Louisiana">Louisiana</option>
              <option value="Maine">Maine</option>
              <option value="Maryland">Maryland</option>
              <option value="Massachusetts">Massachusetts</option>
              <option value="Michigan">Michigan</option>
              <option value="Minnesota">Minnesota</option>
              <option value="Missouri">Missouri</option>
              <option value="Montana">Montana</option>
              <option value="Nebraska">Nebraska</option>
              <option value="Nevada">Nevada</option>
              <option value="New Hampshire">New Hampshire</option>
              <option value="New Jersey">New Jersey</option>
              
              <option value="New York">New York</option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Ohio">Ohio</option>
              <option value="Oklahoma">Oklahoma</option>
              <option value="Oregon">Oregon</option>
              <option value="Pennsylvania">Pennsylvania</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Rhode Island">Rhode Island</option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="Tennessee">Tennessee</option>
              
              <option value="US Virgin Islands">US Virgin Islands</option>
              <option value="Utah">Utah</option>
              <option value="Vermont">Vermont</option>
              <option value="Virginia">Virginia</option>
              <option value="Washington">Washington</option>
              <option value="Washington DC">Washington DC</option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wyoming">Wyoming</option> */}
              </select>
            </>
          )}
        </div>
      </section>
      {/* <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Accumsan mus tortor nunc aliquet</h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum. Donec amet imperdiet
            eleifend
            <br />
            fringilla tincidunt. Nullam dui leo Aenean mi ligula, rhoncus
            ullamcorper.
          </p>
        </header>
        <ul className="features">
          <li className="icon fa-paper-plane">
            <h3>Arcu accumsan</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-laptop">
            <h3>Ac Augue Eget</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-code">
            <h3>Mus Scelerisque</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon solid fa-headphones-alt">
            <h3>Mauris Imperdiet</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Aenean Primis</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
          <li className="icon fa-flag">
            <h3>Tortor Ut</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li>
        </ul>
      </div>
    </section> */}
      {/* <section id="three" className="wrapper style2 special">
        <div className="inner">
          <header className="major">
            <h2>Book Code Lookup</h2>
          </header>
          <ul className="features">
            <li className="icon fa-paper-plane">
              <h3>Enter Book Code</h3>
              <p>Enter your book code to be taken to the test.</p>
              <input
                className="primary"
                type="text"
                placeholder="FLENG51"
                onChange={(e) => setTest(e.target.value)}
              />

              <a
                className="button secondary"
                style={{ width: '100%', marginTop: 10 }}
                onClick={() =>
                  (window.location.href = `/${test.substr(
                    0,
                    2
                  )}/Engineering/${test}`)
                }
              >
                Take Test
              </a>
            </li>
            {/* <li className="icon solid fa-laptop">
              <h3>Test Quiz 1</h3>
              <a
                className="button secondary"
                style={{ width: '100%', marginTop: 10 }}
                onClick={() =>
                  (window.location.href = `/FL/Engineering/FLENG51`)
                }
              >
                Take Test
              </a>
            </li> 
          </ul>
        </div>
      </section> */}

      <section id="cta" className="wrapper style4">
        <div className="inner">
          <header>
            <h3>Have a Question? Contact Us!</h3>
            <p>A representative will respond to you as soon as possible.</p>
          </header>
          <ul className="actions stacked">
            <li>
              <a href="/ContactUs" className="button fit primary">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
